import React, { useEffect, useState } from 'react';
import { BlockComponent } from '@/editor/lib/declarations/EditorComponentTypes';
import Container from '@/components/Container';
import { Typography } from '@mui/material';
import { DimuOwner } from '@/declarations/models/DimuOwner';
import { Api } from '@/services/Api';
import { useController } from 'react-hook-form';
import { BlockSpecificPage } from '@/editor/lib/declarations/BlockSpecificPage';
import { DMAutoBlockModel } from '@/declarations/models/blocks/DMBlock';
import { useCurrentBlock } from '@/editor/PageEditor/CurrentBlockProvider';
import { useTranslation } from 'react-i18next';
import SwitchInput from '@/components/forms/SwitchInput';
import { DMSchoolBlockListingSelects } from '@/editor/PageEditor/EditorBlock/DMSchoolBlock/DMSchoolBlockListingSelects';
import Styles from '@/assets/js/Styles';
import DMSchoolGradeSubjectSelects from '@/editor/PageEditor/EditorBlock/DMSchoolBlock/DMSchoolGradeSubjectSelects';
import ToggleSortingFilteringSettings from '@/editor/PageEditor/EditorBlock/DMSchoolBlock/ToggleSortingFilteringSettings';

const autocompleteItemStyle = (selected: boolean) => ({
  backgroundColor: selected ? 'rgba(0,0,0,0.16) !important' : undefined,
  '&:hover': {
    backgroundColor: 'rgba(0,0,0,0.08) !important',
  },
});

const GRADES = ['kindergarten', 'grade_two', 'grade_four', 'grade_seven', 'grade_ten', 'vg_one', 'vg_two', 'vg_three'];

const SUBJECTS = [
  'SocialStudies',
  'Science',
  'Norwegian',
  'DramaAndRhythmics',
  'ReligionEthicsLifeStance',
  'ArtsAndCrafts',
  'FoodAndHealth',
  'Music',
];

const getOptionLabel = (option: DimuOwner) => `${option.name} (${option.identifier})`;

export const DMSchoolBlock: BlockComponent = () => {
  const { t: tComp } = useTranslation('components');
  const [allDimuOwners, setAllDimuOwners] = useState<Array<DimuOwner>>([]);
  const [selectedOwners, setSelectedOwners] = useState<Array<DimuOwner>>([]);
  const { blockPath } = useCurrentBlock();

  const {
    field: { value: ownerCodes = [], onChange: setOwnerCodes },
  } = useController<BlockSpecificPage<DMAutoBlockModel>, `${typeof blockPath}.ownerCodes`>({
    name: `${blockPath}.ownerCodes`,
  });

  const {
    field: { value: grades = [], onChange: setGrades },
  } = useController<BlockSpecificPage<DMAutoBlockModel>, `${typeof blockPath}.schoolGrades`>({
    name: `${blockPath}.schoolGrades`,
  });

  const {
    field: { value: subjects = [], onChange: setSubjects },
  } = useController<BlockSpecificPage<DMAutoBlockModel>, `${typeof blockPath}.schoolSubjects`>({
    name: `${blockPath}.schoolSubjects`,
  });

  useEffect(() => {
    const ctx = Api.getDimuOwners();
    ctx
      .fetchDirect(null)
      .then((res) => {
        if (res?.owners) {
          setAllDimuOwners(res.owners);
          if (ownerCodes) {
            setSelectedOwners(res.owners.filter((o) => (ownerCodes as string[]).includes(o.identifier)));
          }
        }
      })
      .finally(ctx.abort);
  }, []);

  const handleOwnerCodesChanged = (owners: Array<DimuOwner>) => {
    setSelectedOwners(owners);
    setOwnerCodes(owners.map((o) => o.identifier));
  };

  const handleGradesChanged = (DMSchoolGrades: Array<string>) => {
    setGrades(DMSchoolGrades);
  };

  const handleSubjectsChanged = (DMSchoolGSubjects: Array<string>) => {
    setSubjects(DMSchoolGSubjects);
  };

  return (
    <Container gap={2} column top left fullWidth>
      <DMSchoolGradeSubjectSelects
        tComp={tComp}
        allDimuOwners={allDimuOwners}
        selectedOwners={selectedOwners}
        allDMSchoolGrades={GRADES}
        selectedDMSchoolGrades={Array.isArray(grades) ? grades : []}
        allDMSchoolSubjects={SUBJECTS}
        selectedDMSchoolSubjects={Array.isArray(subjects) ? subjects : []}
        handleOwnerCodesChanged={handleOwnerCodesChanged}
        handleDMSchoolGradesChanged={handleGradesChanged}
        handleDMSchoolSubjectsChanged={handleSubjectsChanged}
        getOptionLabel={getOptionLabel}
        autocompleteItemStyle={autocompleteItemStyle}
      />
      <Container column top left fullWidth>
        <Typography variant='subtitle2'>{tComp('DMBlock.Show')}:</Typography>
        <Container>
          <div>
            <SwitchInput path={`${blockPath}.digitalResources`} label={`${tComp('DMBlock.DigitalResources')}`} />
          </div>
          <div>
            <SwitchInput path={`${blockPath}.visitMuseum`} label={`${tComp('DMBlock.VisitTheMuseums')}`} />
          </div>
        </Container>
      </Container>
      <DMSchoolBlockListingSelects blockPath={blockPath} />
      <Container
        column
        top
        left
        fullWidth
        sx={{
          backgroundColor: Styles.Colors.THEME_BG_COLOR_SECONDARY,
          borderRadius: Styles.Dimensions.NESTED_SECTION_BORDER_RADIUS,
        }}>
        <ToggleSortingFilteringSettings tComp={tComp} blockPath={blockPath} />
      </Container>
    </Container>
  );
};
