import React from 'react';
import { useTranslation } from 'react-i18next';
import { useController } from 'react-hook-form';
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Typography } from '@mui/material';
import { PageStatusCircle } from '@/components/PageStatusCircle';
import DraggableCollapseContainer, {
  DraggableCollapseContainerProps,
} from '../../../../components/DraggableCollapseContainer';
import { useCurrentBlock } from '../../CurrentBlockProvider';
import { CardModuleBlock } from '../../../../declarations/models/blocks/CardModuleBlock';
import { Draggable } from '../../../../declarations/Draggable';
import { BlockSpecificPage } from '../../../lib/declarations/BlockSpecificPage';
import CardModuleBlockActions from './CardModuleBlockActions';
import CardModuleBlockItemHeader from './CardModuleBlockItemHeader';
import CardModuleBlockItemContent from './CardModuleBlockItemContent';
import Container from '../../../../components/Container';
import { CardSize } from '../../../../declarations/models/CardSize';
import TextInput from '../../../../components/forms/TextInput';
import { useEditorEventHandler, useEditorEventManager } from '../../../lib/eventManager/EditorEventManager';
import { BlockItemPathPrefix } from '../../../../declarations/models/BlockItemPathPrefix';
import PageListManualBlockItemSkinSelector from '../PageListBlocks/PageListManualBlockItemSkinSelector';

export interface CardModuleBlockItemProps<T>
  extends Pick<DraggableCollapseContainerProps<T>, 'index' | 'onReorder' | 'onRemove' | 'onAdd'> {
  displayGridSelector: boolean;
}

export const CardModuleBlockItem = <T extends CardModuleBlock['items'][0]>({
  index,
  onReorder,
  onRemove,
  onAdd,
  displayGridSelector,
}: CardModuleBlockItemProps<T>) => {
  const { t: tComp } = useTranslation('components');
  const { blockPath } = useCurrentBlock();
  const eventManager = useEditorEventManager();
  const itemPathPrefix: BlockItemPathPrefix = `${blockPath}.items.${index}`;

  const {
    field: { value },
  } = useController<BlockSpecificPage<CardModuleBlock>, typeof itemPathPrefix>({
    name: itemPathPrefix,
  });
  const item: T = value as T;

  const { field: sizeField } = useController<BlockSpecificPage<CardModuleBlock>, `${typeof itemPathPrefix}.local.size`>(
    {
      name: `${itemPathPrefix}.local.size`,
    },
  );

  const gridSizeSelector = (
    <Container left column fullWidth>
      <FormControl fullWidth>
        <FormLabel sx={{ marginTop: '10px' }}>{tComp(`CardModuleBlockItem.GridSizeSelector.label`)}</FormLabel>
        <RadioGroup {...sizeField} value={sizeField.value || ''} onClick={(e) => e.stopPropagation()} row>
          <FormControlLabel
            control={<Radio size='small' />}
            label={tComp(`CardModuleBlockItem.GridSizeSelector.default`)}
            value=''
          />
          {[CardSize.SMALL, CardSize.MEDIUM, CardSize.LARGE].map((sizeOption) => (
            <FormControlLabel
              key={sizeOption}
              control={<Radio size='small' />}
              label={tComp(`CardModuleBlockItem.GridSizeSelector.${sizeOption}`)}
              value={sizeOption}
            />
          ))}
        </RadioGroup>
      </FormControl>
    </Container>
  );

  return (
    <DraggableCollapseContainer<T>
      useEventHandler={useEditorEventHandler}
      useEventManager={eventManager}
      unmountOnExit
      type={Draggable.CARD_BLOCK_ITEM}
      index={index}
      onReorder={onReorder}
      onRemove={onRemove}
      onAdd={onAdd}
      secondaryAction={
        <>
          <PageListManualBlockItemSkinSelector path={`${itemPathPrefix}.local`} />
          <CardModuleBlockActions onDelete={() => onRemove?.(index)} />
        </>
      }
      headerContent={
        // eslint-disable-next-line react/no-unstable-nested-components
        (open) => <CardModuleBlockItemHeader item={item} open={open} />
      }>
      <CardModuleBlockItemContent item={item}>
        <Container column left fullWidth>
          <Typography fontSize={13}>{item.content?.descr}</Typography>

          {item.page?.title && (
            <>
              <Container>
                <PageStatusCircle status={item.page.status} />
                <Typography fontSize={12}>{item.page?.title}</Typography>
              </Container>
              {item?.page?.url && !item?.content?.url && (
                <Container column left fullWidth>
                  <a
                    target='_blank'
                    rel='noreferrer'
                    href={`//${item?.page?.url || '#'}`}
                    style={{ lineHeight: '1', textDecoration: 'underline', padding: '3px', color: '#666' }}>
                    <Typography fontSize={12}>https://{item.page?.url}</Typography>
                  </a>
                </Container>
              )}

              <Container column left fullWidth>
                {item?.local?.title !== undefined && (
                  <TextInput
                    label='Local title (deprecated)'
                    fullWidth
                    path={`${itemPathPrefix}.local.title`}
                    defaultValue=''
                  />
                )}
                {item?.local?.descr !== undefined && (
                  <TextInput
                    label='Local Description (deprecated)'
                    fullWidth
                    path={`${itemPathPrefix}.local.descr`}
                    defaultValue=''
                    multiline
                  />
                )}
              </Container>
            </>
          )}

          {item?.content?.url && (
            <Container>
              <a
                target='_blank'
                rel='noreferrer'
                href={item?.content?.url || '#'}
                style={{
                  lineHeight: '1',
                  textDecoration: 'underline',
                  padding: '3px',
                  color: '#666',
                  overflowWrap: 'anywhere',
                  width: '100%',
                }}>
                <Typography fontSize={12}>{item?.content?.url}</Typography>
              </a>
            </Container>
          )}

          {item?.type === 'link' && (
            <Container column left fullWidth>
              <TextInput label={tComp('Title')} fullWidth path={`${itemPathPrefix}.local.title`} defaultValue='' />
              <TextInput
                label={tComp('Description')}
                fullWidth
                path={`${itemPathPrefix}.local.descr`}
                defaultValue=''
                multiline
              />
              <TextInput label={tComp('Url')} fullWidth path={`${itemPathPrefix}.local.url`} defaultValue='' />

              {item?.local?.pagelink?.id && (
                <Container>
                  <Typography fontSize={12}>Pagelink:{item?.local?.pagelink?.title}</Typography>
                </Container>
              )}
            </Container>
          )}

          {item?.type === 'article__card' && item?.page_id && (
            <Container column left fullWidth>
              <Typography variant='caption'>{item.content?.url || '#'}</Typography>
            </Container>
          )}

          {displayGridSelector && gridSizeSelector}
        </Container>
      </CardModuleBlockItemContent>
    </DraggableCollapseContainer>
  );
};

export default CardModuleBlockItem;
