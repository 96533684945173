import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Container from '../../../../components/Container';
import { BlockPath } from '../../CurrentBlockProvider';
import TextInput from '../../../../components/forms/TextInput';
import CheckboxInput from '../../../../components/forms/CheckboxInput';

export interface PageListManualBlockItemContentProps {
  index: number;
  blockPath: BlockPath;
  hideTitleInput?: boolean;
  overrideItemPath?: string;
  hideHighlightCheckbox?: boolean;
}

export const PageListManualBlockItemContent: FC<PageListManualBlockItemContentProps> = ({
  index,
  blockPath,
  hideTitleInput = false,
  hideHighlightCheckbox = false,
  overrideItemPath,
}) => {
  const { t: tComp } = useTranslation('components');
  const { t: tCommon } = useTranslation('common');
  const itemPath = overrideItemPath || `${blockPath}.items.${index}`;

  return (
    <Container column p={2} fullWidth>
      <Container fullWidth gap={2} column>
        {!hideTitleInput && (
          <>
            <TextInput
              label={tCommon('title')}
              fullWidth
              path={`${itemPath}.local.title`}
              defaultValue=''
              helperText={tComp('PageListManualBlock.PageListManualBlockItemContent.TitleHelperText')}
            />
            <TextInput
              label={tCommon('description')}
              fullWidth
              path={`${itemPath}.local.description`}
              defaultValue=''
              helperText={tComp('PageListManualBlock.PageListManualBlockItemContent.DescriptionHelperText')}
              multiline
            />
          </>
        )}
        {!hideHighlightCheckbox && (
          <CheckboxInput
            path={`${itemPath}.local.highlight`}
            label={tComp(`PageListManualBlock.PageListManualBlockItemContent.Highlight`)}
          />
        )}
      </Container>
    </Container>
  );
};

export default PageListManualBlockItemContent;
